import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { titleCase } from "@kikoff/utils/src/string";

import { RootState } from "@store";

import amazonPrimeImg from "../../pages/dashboard/subscription-cancellations/assets/amazon_prime.svg";
import disneyPlusImg from "../../pages/dashboard/subscription-cancellations/assets/disney_plus.svg";
import huluImg from "../../pages/dashboard/subscription-cancellations/assets/hulu.svg";
import netflixImg from "../../pages/dashboard/subscription-cancellations/assets/netflix.svg";
import planetFitnessImg from "../../pages/dashboard/subscription-cancellations/assets/planet_fitness.svg";
import spotifyImg from "../../pages/dashboard/subscription-cancellations/assets/spotify.svg";
import { createLoadableSelector, thunk } from "../utils";

import { selectDismissal, selectFeatureFlag } from "./page";
import { selectIsUltimate } from "./shopping";

export import SubscriptionProvider = web.public_.SubscriptionProvider;
export type SubscriptionCancellation = web.public_.ICancellation;
export const SUBSCRIPTION_PROVIDERS = Object.values(
  web.public_.SubscriptionProvider
) as SubscriptionCancellation[];

export const CancellationStatus = web.public_.Cancellation.CancellationStatus;

const initialState = {
  // TODO (Almir): normalize this
  cancellations: null as SubscriptionCancellation[],
};

export type SubscriptiobCancellationState = typeof initialState;

const subscriptiobCancellationSlice = createSlice({
  name: "subscriptionCancellation",
  initialState,
  reducers: {
    setCancellations(
      state,
      { payload }: PayloadAction<SubscriptiobCancellationState["cancellations"]>
    ) {
      state.cancellations = payload;
    },
    updateCancellation(
      state,
      {
        payload,
      }: PayloadAction<SubscriptiobCancellationState["cancellations"][number]>
    ) {
      const index = state.cancellations.findIndex(
        ({ token }) => token === payload.token
      );
      if (index === -1) return;

      state.cancellations[index] = payload;
    },
  },
});

const { actions } = subscriptiobCancellationSlice;
export const {} = actions;
export default subscriptiobCancellationSlice.reducer;

export const useSubscriptionCancellationEnabled = () => {
  const enabled = useSelector(selectFeatureFlag("subscription_cancellation"));
  const [isUltimate, loading] = useSelector(selectIsUltimate.load());

  return loading ? null : enabled && isUltimate;
};

export const selectSubscriptionCancellations = createLoadableSelector(
  () => (state: RootState) => state.subscriptionCancellation.cancellations,
  { loadAction: () => fetchSubscriptionCancellations() }
);

export const selectNewCancellationResults = () => (state: RootState) =>
  state.subscriptionCancellation.cancellations?.filter((cancellation) => {
    const resolved = [
      CancellationStatus.SUCCESS,
      CancellationStatus.FAILURE,
    ].includes(cancellation.cancellationStatus);

    const hasSeen = selectDismissal(
      "SUBSCRIPTION_CANCELLATION_RESULT",
      cancellation.token
    )(state);

    return resolved && !hasSeen;
  }) ?? [];

export const selectSubscriptionCancellationByToken = createLoadableSelector(
  (token: string) => (state: RootState) =>
    state.subscriptionCancellation.cancellations?.find(
      (cancellation) => cancellation.token === token
    ),
  {
    selectLoaded: () => (state) => state.subscriptionCancellation.cancellations,
    loadAction: () => fetchSubscriptionCancellations(),
  }
);

export const fetchSubscriptionCancellations = () => {
  return thunk((dispatch) => {
    return webRPC.SubscriptionManagement.getCancellations({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setCancellations(data.cancellations));

          // Clean up local storage of retry credentials for successful cancellations
          const savedCreds = SubscriptionCancellation.getRetryCredentialsMap();
          for (const providerStr of Object.keys(savedCreds)) {
            const cancellation = data.cancellations.find(
              (cancellation) =>
                cancellation.subscriptionProvider ===
                (Number(providerStr) as SubscriptionProvider)
            );
            const cancelled =
              cancellation?.cancellationStatus === CancellationStatus.SUCCESS;

            if (!cancellation || cancelled) {
              delete savedCreds[providerStr];
            }
          }

          SubscriptionCancellation.updateRetryCredentials(savedCreds);

          return data.cancellations;
        },
        _DEFAULT: handleFailedStatus(
          "Failed to fetch subscription cancellations."
        ),
      })
    );
  });
};

export const submitSubscriptionCancellation = (
  subscriptionProvider: web.public_.SubscriptionProvider,
  credentials: { [key: string]: string }
) => {
  return thunk(() => {
    return webRPC.SubscriptionManagement.initiateCancellation({
      username: credentials.email ?? credentials.username,
      password: credentials.password,
      subscriptionProvider,
    }).then<string>(
      handleProtoStatus({
        SUCCESS(data) {
          return data.cancellationAttemptToken;
        },
        _DEFAULT: handleFailedStatus(
          "Failed to submit subscription cancellation request."
        ),
      })
    );
  });
};

export const pollSubscriptionCancellationStatus = (
  cancellationAttemptToken: string
) => {
  return thunk((dispatch) => {
    return webRPC.SubscriptionManagement.pollCancellationStatus({
      cancellationAttemptToken,
    }).then<{
      status: web.public_.Cancellation.CancellationStatus;
      failureReason?: web.public_.PollCancellationStatusResponse.FailureReason;
      mfaDeliveryAccountName?: string;
    }>(
      handleProtoStatus({
        SUCCESS(data) {
          const needsRefresh = [
            CancellationStatus.LOGGED_IN,
            CancellationStatus.FAILURE,
          ].includes(data.cancellationStatus);
          if (needsRefresh) {
            dispatch(fetchSubscriptionCancellations());
          }

          return {
            status: data.cancellationStatus,
            failureReason: data.failureReason,
            mfaDeliveryAccountName: data.mfaDeliveryAccountName,
          };
        },
        _DEFAULT: handleFailedStatus(
          "Failed to poll subscription cancellations."
        ),
      })
    );
  });
};

export const submitSubscriptionCancellationMfa = (
  cancellationAttemptToken: string,
  mfaCode: string
) => {
  return thunk(() => {
    return webRPC.SubscriptionManagement.sendCancellationMfa({
      cancellationAttemptToken,
      mfaCode,
    }).then(
      handleProtoStatus({
        SUCCESS() {},
        _DEFAULT: handleFailedStatus(
          "Failed to submit subscription cancellation MFA code."
        ),
      })
    );
  });
};

export namespace SubscriptionCancellation {
  export type SubscriptionInfo = {
    provider: SubscriptionProvider;
    title: string;
    price: string;
    icon: string;
    supported: boolean;
    manualInstruction?: {
      customTitle?: string;
      customSubtitle?: string;
      steps: React.ReactNode[];
    };
    credentialRequirements?: CredentialRequirement[];
    mfaDigitsCount?: number;
  };

  export type CredentialRequirement = {
    type: "username" | "email" | "phone" | "password";
    label?: string;
    optional?: boolean;
  };

  export function wrapCredRequirements(
    credReqs: CredentialRequirement[]
  ): CredentialRequirement[] {
    return credReqs.map((credReq) => ({
      ...credReq,
      label: credReq.label ?? titleCase(credReq.type),
    }));
  }

  type RetryCredential = Partial<
    { [key in CredentialRequirement["type"]]: string }
  >;
  type RetryCredentialsMap = Partial<
    { [key in SubscriptionProvider]: RetryCredential }
  >;

  export function getRetryCredentialsMap() {
    const credsStr =
      localStorage.getItem("subscriptionCancellationRetryCreds") ?? "{}";
    return JSON.parse(credsStr) as RetryCredentialsMap;
  }

  export function saveRetryCredential(
    provider: SubscriptionProvider,
    newCreds: RetryCredential
  ) {
    const creds = getRetryCredentialsMap();
    const sanitizedNewCreds = Object.fromEntries(
      Object.entries(newCreds).filter(([key]) => key !== "password")
    );
    creds[provider] = sanitizedNewCreds;
    updateRetryCredentials(creds);
  }

  export function updateRetryCredentials(
    retryCredentials: RetryCredentialsMap
  ) {
    localStorage.setItem(
      "subscriptionCancellationRetryCreds",
      JSON.stringify(retryCredentials ?? {})
    );
  }

  export const getCancellationStatusLabel = (
    status: web.public_.Cancellation.CancellationStatus
  ) => {
    switch (status) {
      case CancellationStatus.SUCCESS:
        return "Cancellation successful";
      case CancellationStatus.FAILURE:
        return "Cancellation could not be completed";
      default:
        return "Cancellation in progress";
    }
  };
}

export const CANCELLABLE_SUBSCRIPTIONS: SubscriptionCancellation.SubscriptionInfo[] = [
  {
    provider: SubscriptionProvider.HULU,
    title: "Hulu",
    price: "$7.99/mo",
    icon: huluImg,
    supported: true,
    credentialRequirements: SubscriptionCancellation.wrapCredRequirements([
      { type: "email" },
      { type: "password" },
    ]),
    mfaDigitsCount: 6,
  },
  {
    provider: SubscriptionProvider.AMAZON_PRIME,
    title: "Amazon Prime",
    price: "$139/yr",
    icon: amazonPrimeImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.amazon.com/gp/primecentral"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.amazon.com/gp/primecentral
          </KLink>{" "}
          (you will be asked to log in, do so then you will be redirected to the
          URL).
        </>,
        "Click the “Manage Membership” button",
        "Click “Update, cancel and more” under the Manage section",
        "Click “End membership”",
        "Scroll down and click “Cancel”",
        "Click “End on [Date]” button",
      ],
    },
  },
  {
    provider: SubscriptionProvider.DISNEY_PLUS,
    title: "Disney+",
    price: "$13.99/mo",
    icon: disneyPlusImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.disneyplus.com/commerce/account?pinned=true"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.disneyplus.com/commerce/account?pinned=true
          </KLink>{" "}
          (you will be asked to log in, do so then you will be redirected to the
          URL).
        </>,
        "Click on your profile on the Navigation Bar",
        "Click “Account”",
        "Click on your subscription in the “Your Plans & Billing” section",
        "Click “Cancel Subscription”",
        "Click “Cancel Subscription” again",
      ],
    },
  },
  {
    provider: SubscriptionProvider.NETFLIX,
    title: "Netflix",
    price: "$15.49/mo",
    icon: netflixImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.netflix.com/login"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.netflix.com/login
          </KLink>
          .
        </>,
        "Click on your profile on the Navigation Bar",
        "Click Account",
        "Click “Manage Membership”",
        "Click “Cancel Membership”",
      ],
    },
  },
  {
    provider: SubscriptionProvider.PLANET_FITNESS,
    title: "Planet Fitness",
    price: "$15/mo",
    icon: planetFitnessImg,
    supported: false,
    manualInstruction: {
      customTitle:
        "To cancel your Planet Fitness membership, you can do it online or in-person at your local branch.",
      customSubtitle:
        "If you want to cancel online, follow the instructions below:",
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://www.planetfitness.com/login"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://www.planetfitness.com/login
          </KLink>{" "}
          (you will be asked to log in, do so then you will be redirected to the
          URL).
        </>,
        "After signing in, click “My Account” on the Navigation Bar",
        "Click “Manage” in Membership Details section",
        "Click “Cancel”",
        "Click “Continue”",
        "Click “Proceed to Payment”",
        "Click “No thanks, I still want to cancel”",
        "Input payment details and click “Pay and Cancel Membership”",
      ],
    },
  },
  {
    provider: SubscriptionProvider.SPOTIFY,
    title: "Spotify",
    price: "$11.99/mo",
    icon: spotifyImg,
    supported: false,
    manualInstruction: {
      steps: [
        <>
          Go to{" "}
          <KLink
            href="https://open.spotify.com/"
            variant="underlined"
            className="color:link"
            style={{ wordBreak: "break-all" }}
          >
            https://open.spotify.com/
          </KLink>
          .
        </>,
        "Click on your profile on the Navigation Bar",
        "Click Account",
        "Click Cancel subscription",
        "Click “Cancel Premium”",
      ],
    },
  },
];

export const useCancellableSubscriptions = () => {
  return CANCELLABLE_SUBSCRIPTIONS;
};

export const getCancellableSubscriptionInfo = (
  provider: SubscriptionProvider
) => {
  return CANCELLABLE_SUBSCRIPTIONS.find((sub) => sub.provider === provider);
};

const MISSING_SUBSCRIPIION_SUGGESTIONS = [
  "Starz",
  "Anytime Fitness",
  "Apple One",
  "Paramount+",
  "Google One",
  "Youtube Premium",
];

export const useMissingSubscriptionSuggestions = () => {
  return MISSING_SUBSCRIPIION_SUGGESTIONS;
};
