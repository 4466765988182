import { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { addBusinessDays, startOfDay } from "date-fns";
import { orderBy } from "lodash-es";
import { Tuple } from "record-tuple";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import Card from "@kikoff/components/src/v1/cards/Card";
import Alert from "@kikoff/components/src/v1/info/Alert";
import ListTile from "@kikoff/components/src/v1/info/ListTile";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { msIn } from "@kikoff/utils/src/date";
import { serverNow } from "@kikoff/utils/src/number";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoTime,
} from "@kikoff/utils/src/proto";
import { cls, format, sentenceCase } from "@kikoff/utils/src/string";
import Table from "@kikoff/utils/src/table";

import NegotiationNotCompletedReasons from "@page/dashboard/negotiations/debt/_components/NegotiationNotCompletedReasons";
import { useOverlaysController } from "@src/overlay";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

import { selectCreditAccount } from "./credit_line";
import { selectDismissal } from "./page";
import { selectIsPremiumOrUltimate } from "./shopping";

const initialState = {
  tokens: null as Negotiation.Token[],
  byToken: {} as Negotiation.ByToken,
  showPopups: true,
  eligibility: null as Negotiations.Eligibility,
};

export type NegotiationsState = typeof initialState;

const negotiationsSlice = createSlice({
  name: "negotiations",
  initialState,
  reducers: {
    setNegotiations(state, { payload }: PayloadAction<Negotiation[]>) {
      const negotiations = payload;

      state.tokens = negotiations.map((negotiation) =>
        Negotiation.token(negotiation)
      );
      state.byToken = Negotiation.ByToken.fromList(negotiations);
    },
    showedPopups(state) {
      state.showPopups = false;
    },
    setEligibility(
      state,
      { payload }: PayloadAction<NegotiationsState["eligibility"]>
    ) {
      state.eligibility = payload;
    },
  },
});

const { actions } = negotiationsSlice;
export const {} = actions;
export default negotiationsSlice.reducer;

export namespace Negotiations {
  export namespace Debt {
    export const isNewUser = (debtNegotiations: Negotiation.Debt[]) =>
      debtNegotiations.every(Negotiation.Debt.isEligible);
  }

  export type Eligibility = web.public_.GetNegotiationsResponse.Eligibility;
}

export type Negotiation = web.public_.INegotiation;
export namespace Negotiation {
  export type Token = string & {};
  export const token = (negotiation: Negotiation) => negotiation.debt.token;

  export type Type = keyof Negotiation;
  export const types = ["debt"] as Negotiation.Type[];

  export type ByToken = Record<Negotiation.Token, Negotiation>;
  export namespace ByToken {
    export const fromList = (negotiations: Negotiation[]) =>
      Table.createIndex(negotiations, [Negotiation.token]);
  }

  export type Debt = web.public_.IDebt;
  export namespace Debt {
    export namespace Servicing {
      export enum Stage {
        ELIGIBLE,
        IN_PROGRESS,
        SUCCESS,
        FAILURE,
      }

      export enum Priority {
        NONE,
        INFO,
        ACTION_AVAILABLE,
        ACTION_REQUIRED,
      }

      export type Context = "home" | "account" | "accounts-card" | "overlay";

      export type State = {
        stage: Stage;
        priority: Priority;
        StatusText: React.FC<{ context: Context }>;
        NextStep?: React.FC<{ context: Context }>;
        Cta?: React.FC<{ context: Context }>;
      };
    }

    export type OfferType = typeof Negotiation.Debt.offerTypes[number];
    export const offerTypes = ["one-time", "monthly"] as const;
    export const cadenceByOfferType: Record<OfferType, web.public_.Cadence> = {
      "one-time": web.public_.Cadence.ONE_TIME,
      monthly: web.public_.Cadence.MONTHLY,
    };

    const nameByCreditor: Record<web.public_.Debt.Creditor, string> = {
      [web.public_.Debt.Creditor.LVNV]: "LVNV Funding",
      [web.public_.Debt.Creditor.MIDLAND]: "Midland Credit Management",
      [web.public_.Debt.Creditor.CAPITAL_ONE]: "Capital One Bank",
      [web.public_.Debt.Creditor.JEFFERSON]: "Jefferson Capital",
    };
    export const creditorName = ({ creditor }: Negotiation.Debt) =>
      nameByCreditor[creditor];
    export const creditors = () => Object.values(nameByCreditor);

    export const negotiationStatusName = ({
      negotiationStatus,
    }: Negotiation.Debt) => web.public_.NegotiationStatus[negotiationStatus];

    export namespace daysTo {
      export const negotiate = 3;
      export const acceptOffer = 5;
    }
    export const firstPaymentDateRange = (
      requestDate = startOfDay(new Date())
    ) => {
      return {
        start: addBusinessDays(
          requestDate,
          Negotiation.Debt.daysTo.negotiate +
            Negotiation.Debt.daysTo.acceptOffer
        ),
        end: addBusinessDays(requestDate, 33),
      };
    };

    export const daysToFirstPayment = ({ offerDetails }: Negotiation.Debt) =>
      Math.ceil(
        (protoTime(offerDetails.firstPaymentDate) - serverNow()) / msIn.day
      );

    export const firstPaymentAmount = ({ offerDetails }: Negotiation.Debt) => {
      if (offerDetails.cadence === web.public_.Cadence.MONTHLY) {
        return offerDetails.paymentSchedule[0].amountCents;
      }

      return offerDetails.amountCents;
    };

    export const monthlyPaymentAmount = ({ offerDetails }: Negotiation.Debt) =>
      offerDetails.paymentSchedule[1].amountCents;

    export const isEligible = ({ negotiationStatus }: Negotiation.Debt) =>
      negotiationStatus === web.public_.NegotiationStatus.ELIGIBLE;

    export const savings = ({ balanceCents, offerDetails }: Negotiation.Debt) =>
      balanceCents - offerDetails.amountCents;

    export const type = ({ type }: Negotiation.Debt) =>
      sentenceCase(web.public_.Debt.DebtType[type], "_", "-");
  }
}

const selectNegotiationsLoaded = () => (state: RootState) =>
  state.negotiations.tokens;

export const selectNegotiations = Object.assign(
  createLoadableSelector(
    () => (state: RootState) =>
      state.negotiations.tokens?.map(
        (token) => state.negotiations.byToken[token]
      ),
    {
      loadAction: () => fetchNegotiations(),
      selectLoaded: selectNegotiationsLoaded,
    }
  ),
  Object.fromEntries(
    Negotiation.types.map((type) => [
      type,
      createLoadableSelector(
        () => (state: RootState) =>
          state.negotiations.tokens?.flatMap(
            (token) => state.negotiations.byToken[token][type] || []
          ),
        {
          loadAction: () => fetchNegotiations(),
          selectLoaded: selectNegotiationsLoaded,
        }
      ),
    ])
  ) as {
    [Type in Negotiation.Type]: createLoadableSelector.Result<
      [],
      Negotiation[Type][]
    >;
  }
);

export const selectNegotiation = createLoadableSelector(
  (token: Negotiation.Token) => (state: RootState) =>
    state.negotiations.byToken[token],
  {
    loadAction: () => fetchNegotiations(),
    selectLoaded: selectNegotiationsLoaded,
  }
);

export const selectEligibility = createLoadableSelector(
  () => (state: RootState) => state.negotiations.eligibility,
  {
    loadAction: () => fetchNegotiations(),
  }
);

export const selectDebtNegotiationsEligibleToStart = () => (
  state: RootState
) => {
  const eligibility = selectEligibility()(state);

  return (
    eligibility === null ||
    eligibility === web.public_.GetNegotiationsResponse.Eligibility.ELIGIBLE
  );
};

export const selectDebtNegotiationServicingState = (() => {
  let ref = null;
  let cache = null;

  return () => (state: RootState) => {
    const debtNegotiations = selectNegotiations.debt()(state);
    const eligibleToStart = selectDebtNegotiationsEligibleToStart()(state);

    if (!debtNegotiations) return null;

    const nextRef = Tuple(state.negotiations.byToken, eligibleToStart);
    if (ref === nextRef) return cache as never;
    ref = nextRef;

    const servicingStateByToken = Object.fromEntries(
      debtNegotiations
        .map(
          (debtNegotiation) =>
            [
              debtNegotiation.token as Negotiation.Token,
              {
                ...((): Omit<
                  Negotiation.Debt.Servicing.State,
                  "debtNegotiation"
                > => {
                  const {
                    token,
                    negotiationStatus,
                    balanceCents,
                    offerDetails,
                    negotiationResultNotes,
                    completedAt,
                  } = debtNegotiation;

                  switch (negotiationStatus) {
                    case web.public_.NegotiationStatus.ELIGIBLE:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.ELIGIBLE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText({ context }) {
                          return (
                            <div
                              className={cls(
                                context === "home" && "color:error"
                              )}
                            >
                              {Negotiation.Debt.type(debtNegotiation)} account
                            </div>
                          );
                        },
                        Cta() {
                          return (
                            eligibleToStart && (
                              <KLink
                                href={`/dashboard/negotiations/debt/start/${token}/offer-type`}
                              >
                                <KButton.RequireContext>
                                  Request offer
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.NEGOTIATION_REQUESTED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.IN_PROGRESS,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return (
                            <div className="color:success">
                               Negotiation in progress
                            </div>
                          );
                        },
                        NextStep() {
                          return (
                            <>
                              <div className="mb-2">
                                You have requested an offer for your{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                                {Negotiation.Debt.type(debtNegotiation)} account
                                ({format.money(balanceCents)}) on{" "}
                                {format.date(
                                  debtNegotiation.negotiationPreference
                                    .requestedAt,
                                  "Mmm d"
                                )}
                              </div>
                              <div>
                                We’ll notify you when we receive a response.
                                Please also keep an eye on your email for an
                                offer from{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}
                                .
                              </div>
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.OFFER_RECEIVED:
                    case web.public_.NegotiationStatus.OFFER_AVAILABLE:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.IN_PROGRESS,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_REQUIRED,
                        StatusText({ context }) {
                          return (
                            <>
                              <div className="color:success">
                                 Offer arrived
                              </div>
                              {["accounts-card", "home"].includes(context) && (
                                <div className="mt-0.5">
                                   First payment due on{" "}
                                  {format.date(
                                    offerDetails.firstPaymentDate,
                                    "Mmm d"
                                  )}
                                </div>
                              )}
                            </>
                          );
                        },
                        NextStep() {
                          const email = useSelector(
                            (state) => state.user.proto.info.email
                          );

                          return (
                            <>
                              <div className="mb-2">
                                We’ve secured a settlement offer from{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}
                                . Review your offer, and make payment(s). Your
                                first payment is due by{" "}
                                <b>
                                  {format.date(
                                    offerDetails.firstPaymentDate,
                                    "Mmm d"
                                  )}
                                </b>
                                .
                              </div>
                              <div className="text:large+ mb-1">Next step:</div>
                              <div className="text:regular++">
                                {negotiationStatus ===
                                web.public_.NegotiationStatus.OFFER_RECEIVED
                                  ? `Please check the email associated with your Kikoff account (${email}) for details. It takes up to 72 business hours for the email to be sent.`
                                  : "Please refer to the special instructions to review your offer."}
                              </div>
                              {offerDetails.specialInstructions && (
                                <Alert
                                  className="mt-3"
                                  type="info"
                                  dugout
                                  title="Special instructions"
                                  description={offerDetails.specialInstructions}
                                />
                              )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          const overlays = useOverlaysController();

                          return (
                            context === "account" && (
                              <>
                                <KButton.RequireContext
                                  onClick={() => {
                                    overlays.push(
                                      negotiationStatus ===
                                        web.public_.NegotiationStatus
                                          .OFFER_RECEIVED
                                        ? "src/pages/dashboard/negotiations/debt/_views/open_email"
                                        : "src/pages/dashboard/negotiations/debt/_views/follow_special_instructions"
                                    );
                                  }}
                                >
                                  Review my offer
                                  {negotiationStatus ===
                                    web.public_.NegotiationStatus
                                      .OFFER_RECEIVED && " via email"}
                                </KButton.RequireContext>
                                <KButton
                                  className="mt-3"
                                  variant="black-outline"
                                  size="full-width"
                                  onClick={() => {
                                    overlays.push(
                                      "src/pages/dashboard/negotiations/debt/_views/mark_as_paid",
                                      { token }
                                    );
                                  }}
                                >
                                  Mark as paid
                                </KButton>
                              </>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.PAYMENT_STARTED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.SUCCESS,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return (
                            <div className="color:success">Payment started</div>
                          );
                        },
                        NextStep() {
                          return (
                            <>
                              <div className="text:regular++">Great job!</div>
                              <div>
                                Please note: Once your settlement is paid off
                                and on-time, this debt will be removed from your
                                credit report in 30-60 days.
                              </div>
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.PAID_OFF:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.SUCCESS,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return <div className="color:success">Paid off</div>;
                        },
                        NextStep() {
                          return (
                            <>
                              <div className="text:regular++">Great job!</div>
                              <div>
                                Please note: Once your settlement is paid off,
                                this debt will be removed from your credit
                                report in 30-60 days.
                              </div>
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.USER_CANCELLED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText() {
                          return (
                            <div className="color:error">
                               Negotiation cancelled
                            </div>
                          );
                        },
                        NextStep() {
                          return (
                            <div>
                              You’ve cancelled your negotiation request for this
                              debt account,
                            </div>
                          );
                        },
                        Cta({ context }) {
                          return (
                            context === "account" &&
                            eligibleToStart && (
                              <KLink
                                href={`/dashboard/negotiations/debt/start/${token}/offer-type`}
                              >
                                <KButton.RequireContext>
                                  Request a new offer for this account
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.NO_OFFER:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText() {
                          return (
                            <div className="color:error">
                               No offer available
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          return (
                            <>
                              <div>
                                We requested a money-saving offer for your{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                                debt, but unfortunately no offers are available
                                at this time for this particular account.
                              </div>
                              {context === "overlay" && (
                                <Card className="mt-1" outline raised>
                                  <ListTile
                                    title={debtNegotiation.accountName}
                                    subtitle={`${Negotiation.Debt.type(
                                      debtNegotiation
                                    )} account`}
                                    trailing={format.money(
                                      debtNegotiation.balanceCents
                                    )}
                                    propsFor={{
                                      title: { className: "text:large+" },
                                      subtitle: { className: "color:error" },
                                      trailing: { className: "text:large+" },
                                    }}
                                    noPadding
                                  />
                                </Card>
                              )}
                              {negotiationResultNotes && (
                                <Alert
                                  className={
                                    context === "overlay" ? "mt-1" : "mt-3"
                                  }
                                  type="info"
                                  dugout
                                  title="Notes"
                                  description={negotiationResultNotes}
                                />
                              )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          const hasEligibleNegotiation = debtNegotiations.some(
                            Negotiation.Debt.isEligible
                          );
                          return (
                            ["account", "overlay"].includes(context) &&
                            hasEligibleNegotiation &&
                            eligibleToStart && (
                              <KLink href="/dashboard/negotiations/debt/start">
                                <KButton.RequireContext>
                                  Request an offer for another account
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.OFFER_EXPIRED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return (
                            <div className="color:error">
                               Payment due on{" "}
                              {format.date(
                                offerDetails.firstPaymentDate,
                                "Mmm d"
                              )}{" "}
                              has passed
                            </div>
                          );
                        },
                        NextStep() {
                          return (
                            <div>
                              Your offer arrived on{" "}
                              {format.date(completedAt, "Mmm d")}, but your
                              payment due on{" "}
                              {format.date(
                                offerDetails.firstPaymentDate,
                                "Mmm d"
                              )}{" "}
                              has passed.
                            </div>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.UNABLE_TO_COMPLETE:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText() {
                          return (
                            <div className="color:error">
                               Negotiation could not be completed
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          return (
                            <>
                              <div className="mb-2">
                                The negotiation for your{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                                account could not be completed due to one or
                                more of the following reasons:
                              </div>
                              <NegotiationNotCompletedReasons />
                              {context === "overlay" && (
                                <Card className="mt-2" outline raised>
                                  <ListTile
                                    title={debtNegotiation.accountName}
                                    subtitle={`${Negotiation.Debt.type(
                                      debtNegotiation
                                    )} account`}
                                    trailing={format.money(
                                      debtNegotiation.balanceCents
                                    )}
                                    propsFor={{
                                      title: { className: "text:large+" },
                                      subtitle: { className: "color:error" },
                                      trailing: { className: "text:large+" },
                                    }}
                                    noPadding
                                  />
                                </Card>
                              )}
                              {negotiationResultNotes && (
                                <Alert
                                  className="mt-2"
                                  type="info"
                                  dugout
                                  title="Notes"
                                  description={negotiationResultNotes}
                                />
                              )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          const hasEligibleNegotiation = debtNegotiations.some(
                            Negotiation.Debt.isEligible
                          );

                          return (
                            ["account", "overlay"].includes(context) &&
                            hasEligibleNegotiation &&
                            eligibleToStart && (
                              <KLink href="/dashboard/negotiations/debt/start">
                                <KButton.RequireContext>
                                  Request an offer for another account
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    default:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.ELIGIBLE,
                        priority: Negotiation.Debt.Servicing.Priority.NONE,
                        StatusText() {
                          return <>Collection account</>;
                        },
                      };
                  }
                })(),
                debtNegotiation,
              } as Negotiation.Debt.Servicing.State,
            ] as const
        )
        .map(([token, state]) => {
          const { NextStep, Cta } = state;

          const wrapWithCommon = <C extends React.FC<any>>(Component: C) =>
            Component &&
            (((props) => (
              <KLink.PropsProvider
                variant="container"
                replace={false}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Component {...props} />
              </KLink.PropsProvider>
            )) as C);

          return [
            token,
            Object.assign(state, {
              NextStep: wrapWithCommon(NextStep),
              Cta: wrapWithCommon(Cta),
            }),
          ];
        })
    );

    return (cache = {
      byToken: servicingStateByToken,
      byStage: orderBy(
        Object.entries(servicingStateByToken).map(
          ([token, servicingState]) => ({
            ...servicingState,
            debtNegotiation: selectNegotiation(token)(state).debt,
          })
        ),
        ({ priority }) => priority,
        "desc"
      ).groupBy(
        ({ stage }) =>
          Negotiation.Debt.Servicing.Stage[
            stage
          ] as keyof typeof Negotiation.Debt.Servicing.Stage
      ),
    });
  };
})();

export const fetchNegotiations = Object.assign(
  () =>
    thunk((dispatch, getState) =>
      webRPC.Negotiations.getNegotiations({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            const negotiations = data.negotiations;
            const eligibility = data.eligibility;

            dispatch(actions.setNegotiations(negotiations));
            dispatch(actions.setEligibility(eligibility));

            return negotiations;
          },
          _DEFAULT: handleFailedStatus("Failed to load negotiations."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { tokens, byToken } = getState().negotiations;

        return Promise.resolve(
          tokens?.map((token) => byToken[token]) ||
            dispatch(fetchNegotiations())
        );
      }),
  }
);

export const useDebtNegotiationsEnabled = () => {
  const creditLine = useSelector(selectCreditAccount());
  const [eligibility] = useSelector(selectEligibility.load());

  const ineligible = [
    web.public_.GetNegotiationsResponse.Eligibility.NOT_ELIGIBLE_STATE_BLOCKED,
    web.public_.GetNegotiationsResponse.Eligibility.NOT_ELIGIBLE_FF,
  ];

  return !creditLine?.closedAt && !ineligible.includes(eligibility);
};

export const useDebtNegotiationsEligible = () => {
  const isPremiumOrUltimate = useSelector(selectIsPremiumOrUltimate.load());

  return isPremiumOrUltimate;
};

export const useNegotiationsPopups = () => {
  const dispatch = useDispatch();
  const overlays = useOverlaysController();

  const store = useStore();
  const showPopups = useSelector(
    (state: RootState) => state.negotiations.showPopups
  );
  const debtNegotiationsEnabled = useDebtNegotiationsEnabled();

  useEffect(() => {
    if (debtNegotiationsEnabled && showPopups) {
      (async () => {
        dispatch(actions.showedPopups());
        const negotiations = (await dispatch(
          fetchNegotiations.ifNotPresent()
        )) as Negotiation[];

        for (const negotiation of negotiations) {
          const debtNegotiation = negotiation.debt;

          if (
            [
              web.public_.NegotiationStatus.OFFER_RECEIVED,
              web.public_.NegotiationStatus.OFFER_AVAILABLE,
            ].includes(debtNegotiation?.negotiationStatus) &&
            !selectDismissal(
              "DEBT_NEGOTIATION_REVIEW_OFFER",
              debtNegotiation.token
            )(store.getState())
          ) {
            overlays.push(
              "src/pages/dashboard/negotiations/debt/_views/review_offer",
              { token: debtNegotiation.token }
            );
          }

          if (
            debtNegotiation?.negotiationStatus ===
              web.public_.NegotiationStatus.NO_OFFER &&
            !selectDismissal(
              "DEBT_NEGOTIATION_OFFER_NOT_AVAILABLE",
              debtNegotiation.token
            )(store.getState())
          ) {
            overlays.push(
              "src/pages/dashboard/negotiations/debt/_views/offer_not_available",
              { token: debtNegotiation.token }
            );
          }

          if (
            debtNegotiation?.negotiationStatus ===
              web.public_.NegotiationStatus.UNABLE_TO_COMPLETE &&
            !selectDismissal(
              "DEBT_NEGOTIATION_NOT_COMPLETED",
              debtNegotiation.token
            )(store.getState())
          ) {
            overlays.push(
              "src/pages/dashboard/negotiations/debt/_views/negotiation_not_completed",
              { token: debtNegotiation.token }
            );
          }
        }
      })();
    }
  }, []);
};
