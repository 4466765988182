import { createPropsProvider } from "@kikoff/client-utils/src/react";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./KButton.module.scss";

declare namespace KButton {
  type Props<
    As extends ElementCreatable = "button"
  > = React.ComponentPropsWithoutRef<As> & {
    as?: As;
    className?: string;
    variant:
      | "primary"
      | "black"
      | "container"
      | "dugout"
      | "primary-dugout"
      | "primary-outline"
      | "black-outline"
      | "grey-outline"
      | "text-primary"
      | "text-underline"
      | "text-discouraged"
      | "accent"
      | "orange-gradient";

    size: "hug" | "small" | "standard" | "full-width";
    wide?: boolean;
    state?: "active" | "disabled";
    children?: React.ReactNode;
    asBlock?: boolean;
    requireBackground?: boolean;
  };
}

export default KButton;
function KButton<As extends ElementCreatable = "button">(
  _props: KButton.Props<As>
) {
  const {
    variant,
    state = "active",
    size,
    wide,
    className,
    as: Component = "button" as As,
    children,
    asBlock,
    style,
    requireBackground,
    ...props
  } = KButton.PropsProvider.useMerge(_props);

  return (
    // @ts-expect-error
    <Component
      {...(Component === "button" && { type: "button" })}
      className={combineClasses(
        styles["k-button"],
        styles[`variant_${variant}`],
        styles[`state_${state}`],
        styles[`size_${size}`],
        wide && styles.wide,
        requireBackground && styles["require-background"],
        "text:regular",
        className
      )}
      disabled={state === "disabled"}
      style={{ ...style, ...(asBlock && { display: "block" }) }}
      {...props}
    >
      {children}
    </Component>
  );
}

KButton.PropsProvider = createPropsProvider<Partial<KButton.Props>>("KButton");

declare namespace KButton {
  namespace RequireContext {
    interface Props extends Partial<KButton.Props> {}
  }
}

KButton.RequireContext = (props: KButton.RequireContext.Props) => {
  const context = KButton.PropsProvider.useContext();

  if (context === createPropsProvider.defaultContext)
    throw new Error(
      "KButton.RequireContext rendered outside of KButton.PropsProvider"
    );

  if (!context.variant || !context.size)
    throw new Error(
      "KButton.RequireContext requires a KButton.PropsProvider context with both variant and size set"
    );

  return <KButton {...(props as KButton.Props)} />;
};

declare namespace KButton {
  namespace WithoutContext {
    interface Props extends KButton.Props {}
  }
}

KButton.WithoutContext = (props: KButton.WithoutContext.Props) => {
  return (
    <KButton.PropsProvider.Context.Provider
      value={createPropsProvider.defaultContext}
    >
      <KButton {...props} />
    </KButton.PropsProvider.Context.Provider>
  );
};
